import React, { useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Loading, Modal, Textinput, Typo, Button } from '../../../ui';
import { generateGiftCodes } from '../../invites/invitesSlice';
import { CSVLink } from '../../createCsv';
import dayjs from 'dayjs';


const Controls = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;

const GenerateGiftCodesModal = ({ course, onClose }) => {
  const dispatch = useDispatch();
  const amountRef = useRef(null);
  const [exportData, setExportData] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleCreateGiftCodes = useCallback(() => {
    setLoading(true)
    dispatch(generateGiftCodes({ amount: amountRef.current.value, courseId: course.id }))
      .then((res) => {
        const data = res.payload.map(item => ({
          "codes": item
        }))
        setLoading(false)
        setExportData(data)
      })
  }, [dispatch, course, setLoading, setExportData]);

  return (
    <Modal
      open={!!course}
      onClose={onClose}
      title={course?.name}
      width='650px'
    >
      <Typo.Text>Specify how many codes to create</Typo.Text>
      <Textinput
        autoFocus
        type='number'
        inputRef={amountRef}
        margin='dense'
        id='giftCodes'
        fullWidth
      />

      <Button icon='Sync' disabled={amountRef?.current?.value} onClick={handleCreateGiftCodes}>{'Create gift codes'}</Button>
      <Controls>
        {loading ? <Loading /> :
          exportData?.length && <CSVLink
            data={exportData}
            filename={`gift codes ${course?.name} ${dayjs().format('YYYY-MM-DD')}.csv`}
          >
            Download file
          </CSVLink>}
      </Controls>

    </Modal>
  );
};

export default GenerateGiftCodesModal;
